var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("全局Loading加载动画")]), _c('Button', {
    on: {
      "click": _vm.showLoading
    }
  }, [_vm._v("显示右上角加载动画")]), _c('Button', {
    staticStyle: {
      "margin-left": "5px"
    },
    on: {
      "click": _vm.closeLoading
    }
  }, [_vm._v("关闭右上角加载动画")]), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("使用方式")]), _vm._v(" 修改全局Vuex即可，开启： "), _c('code', [_vm._v("this.$store.commit(\"setLoading\", true)")]), _vm._v(" 关闭： "), _c('code', [_vm._v("this.$store.commit(\"setLoading\", false)")]), _c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("iView官方 LoadingBar加载进度条")]), _c('Button', {
    on: {
      "click": _vm.start
    }
  }, [_vm._v("开始加载")]), _c('Button', {
    staticStyle: {
      "margin-left": "5px"
    },
    on: {
      "click": _vm.finish
    }
  }, [_vm._v("结束加载")]), _c('Button', {
    staticStyle: {
      "margin-left": "5px"
    },
    on: {
      "click": _vm.error
    }
  }, [_vm._v("错误")]), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("说明")]), _vm._v(" 如果你觉得上方XBoot提供的动画不明显，你还可以使用iView官方自带的顶部 "), _c('a', {
    attrs: {
      "href": "https://www.iviewui.com/components/loading-bar",
      "target": "_blank"
    }
  }, [_vm._v("LoadingBar组件")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };